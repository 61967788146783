<template>
  <div style="overflow-x:clip; padding:0 10px;" v-swiper:ReviewSlider="settings">
    <div class="swiper-wrapper">
      <slot />
    </div>
  </div>
</template>
<script>
export default {
  name: 'ReviewSlider',
  data () {
    return {
      settings: {
        slidesPerView: 1,
        autoHeight: true,
        direction: "horizontal",
        loop: true,
        spaceBetween: 40,
        speed: 2000,
        autoplay: {
          delay: 3000,
        },
        noSwiping: false,
        allowTouchMove: true,
        breakpoints: {
          992: {
            slidesPerView: 1,
            spaceBetween: 30,
          }
        }
      }
    }
  }
}
</script>
