<template>
  <div style="overflow-x:clip; padding:0 10px;" v-swiper:ReviewSlider="settings">
    <div class="swiper-wrapper">
      <slot />
    </div>

      <div class="swiper-scrollbar mt-8">
        <div class="line h-px bg-primary" style="margin-bottom:-3.5px;"></div>
      </div>
  </div>
</template>
<script>
export default {
  name: 'ReviewSlider',
  data () {
    return {
      settings: {
        slidesPerView: 1,
        autoHeight: true,
        direction: "horizontal",
        loop: false,
        spaceBetween: 40,
        noSwiping: false,
        scrollbar: {
          el: '.swiper-scrollbar',
          draggable: true,
          dragSize: 100.
        },
        allowTouchMove: true,
        breakpoints: {
          992: {
            slidesPerView: 1,
            spaceBetween: 30,
          }
        }
      }
    }
  }
}
</script>
