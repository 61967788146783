<template>
  <div id="nav" class="relative z-30">
    <div id="addc" class="absolute top-0 left-0 right-0 bottom-0">

    </div>

    <div class="w-screen h-px bg-white z-10 absolute opacity-20 bottom-0"></div>
    <div class="border-nav w-screen overflow-hidden relative z-40 px-8">
      <div class="container py-4 lg:py-8 relative back transition-all ease-in-out duration-300">
        <div class="md:w-full relative flex justify-between items-center lg:gap-x-12">
          <div class="relative mb-0">
            <slot name="brand" />
          </div>
          <div class="h-6">
            <a href="Tel:+44 (0) 28 9244 9800" class="hidden lg:inline-block text-primary text-lg mr-8 hover:text-white ease-in-out duration-300">+44 (0) 28 9244 9800</a>
            <button name="main menu" aria-label="main menu" type="button" @click="isMenu = !isMenu; isActive = !isActive; isSlid = true; isSlid = true;addo();addh();addbg();addoanim();" class="text-center text-primary toggle-me font-lato text-sm uppercase tracking-widest	">
              <div id="nav-icon4" :class="{ open: isActive }">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
<!--    &#45;&#45;-->
<!--    <div :class="{ desktopmenu: isActive }"  class="absolute overflow-hidden z-30 main-show w-screen block h-screen bg-secondary top-0 right-0 left-0 bottom-0 invisi">-->
<!--      <img src="/app/themes/wordpress/assets/images/lines.svg" alt="Lines shapes" class="absolute -right-8 w-28 h-4/5 -bottom-8 ">-->
<!--      <div class="relative h-screen">-->
<!--        <div class="w-screen left-0 transition-all ease-in-out duration-300 mover h-screen flex items-center absolute mx-auto px-4 lg:px-8 py-4 lg:py-8">-->
<!--          <div class="max-w-screen-xl 2xl:max-w-screen-2xl mx-auto px-4 md:px-8 lg:px-12 2xl:px-8 w-full flex gap-x-32">-->
<!--            <div class="w-1/4">-->
<!--              <slot name="mainmenu" />-->
<!--            </div>-->
<!--            <div>-->
<!--              <slot name="menuservices" />-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
<!--    &#45;&#45;-->
    <div :class="{ desktopmenu: isActive }"  class="absolute overflow-hidden z-30 main-show w-screen block h-screen bg-secondary top-0 right-0 left-0 bottom-0 invisi">
      <img src="/app/themes/wordpress/assets/images/menu-box.svg" alt="Lines shapes" class="absolute hidden lg:block right-0 -bottom-0 ">
      <img src="/app/themes/wordpress/assets/images/menu-l.svg" alt="Lines shapes" class="absolute hidden lg:block sm:left-0 bottom-0 lg:-left-12 2xl:-left-24 h-4/6">
      <img src="/app/themes/wordpress/assets/images/big-lines.svg" alt="Lines shapes" class="absolute lg:hidden bottom-0 -left-32 h-4/5">
      <div class="relative h-screen lg:block hidden">
        <div class="w-screen left-0 transition-all ease-in-out duration-300 mover h-screen flex items-center absolute mx-auto px-4 lg:px-8 py-4 lg:py-8">
          <div class="max-w-screen-xl 2xl:max-w-screen-2xl mx-auto px-4 md:px-8 lg:px-12 2xl:px-8 w-full flex gap-x-32">
            <div class="w-1/4 relative pt-12 bg-secondary">
              <img src="/app/themes/wordpress/assets/images/shape-na.svg" class="absolute w-full top-4 left-4" alt="ni precision">
              <div class="mx-auto relative pl-32">
                <p class="text-primary font-source tracking-200 comp opacity-0 text-base mb-6">COMPANY</p>
                <slot name="mainmenu" />
              </div>
            </div>
            <div>
              <slot name="menuservices" />
            </div>
          </div>
        </div>
      </div>
      <!--      split-->
      <div class="relative h-screen lg:hidden">
        <div class="w-screen left-0 overflow-y-scroll scrollers transition-all ease-in-out duration-300 mover h-screen items-center absolute mx-auto px-4 lg:px-8 py-4 lg:py-8">
          <div class="w-full pt-32 bg-secondary relative overflow-y-scroll">
              <slot name="menuservices" />
            <div class="relative py-12 mt-10 bg-secondary">
              <img src="/app/themes/wordpress/assets/images/shape-na.svg" class="absolute w-1/2 brightness-125" alt="ni precision" style="left:25%; top:1rem;">
              <div class="text-center relative pb-44">
                <p class="text-primary font-source tracking-200 comp opacity-0 text-base mb-6">COMPANY</p>
                <slot name="mainmenu" />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      isOpen: false,
      isOpenLeft: false,
      services:false,
      isActive: false,
      isSlid: true,
      isMenu: false,
    }
  },
  methods: {
    addo () {
      document.querySelector('body').classList.toggle('overflow-hidden')
    },
    addom () {
      document.querySelector('.desktopmenu').classList.toggle('offscreen')
    },
    addomt () {
      document.querySelector('.moverin').classList.toggle('onscreen')
    },
    addoanim () {
      document.querySelector('.menu-item').classList.toggle('test')
    },
    addh () {
      document.querySelector('body').classList.toggle('max-h-screen')
    },
    addbg () {
      document.querySelector('.back').classList.toggle('bg-secondary')
    },
    removeo () {
      document.querySelector('body').classList.toggle('overflow-hidden')
    },
    removeh () {
      document.querySelector('body').classList.toggle('max-h-screen')
    },
  }
}
</script>
<style lang="scss">
.moverin{
  right: -101vw;
}
.onscreen{
  right: 0!important;
}
.offscreen .mover{
  left: -101vw!important;
}
.invisi{
  margin-left: -105vw;
  transition: ease-in-out 0.5s all;
}
.invisit{
  margin-left: -105vw;
  transition: ease-in-out 0.5s all;
}
.invisib{
  margin-left: -200vw;
  transition: ease-in-out 0.5s all;
}
.desktopmenu{
  margin-left: 0;
}
.slideit{
  animation: slideit 1.5s;
}

@keyframes slideit {
  0% {
    margin-left: -105vw;
  }
  30% {
    margin-left: 0;
  }
  70%{
    margin-left: 0;
  }
  100% {
    margin-left: 105vw;
  }
}
</style>
