<template>
  <div>
    <div class="lg:absolute bottom-24">
      <button class="flex items-center group mt-4 sm:mt-0 gap-x-3" @click="selecttab(1);hidebod();height();">
                 <span class="relative sm:p-4 text-white group-hover:text-primary transition ease-in-out duration-300">
                     <svg xmlns="http://www.w3.org/2000/svg" class="w-12 lg:w-20" viewBox="0 0 88 88">
                      <circle cx="44" cy="44" r="44" fill="rgba(255,255,255,0.2)"/>
                      <g transform="translate(16 16)" fill="none" stroke="currentColor" stroke-width="2">
                        <circle cx="28" cy="28" r="28" stroke="none"/>
                        <circle cx="28" cy="28" r="27" fill="none"/>
                      </g>
                      <path id="Polygon_2" data-name="Polygon 2" d="M8.488,0l8.488,14.855H0Z" transform="translate(32.756 39.023) rotate(-30)" fill="currentColor"/>
                    </svg>

                 </span>
        <span class="text-white group-hover:text-primary transition ease-in-out duration-300">
                     Play Full Video
                 </span>
      </button>
    </div>
    <div v-if="selected===1" class="fixed opacity-100 z-40 w-screen h-screen flex items-center top-0 left-0 right-0 bottom-0">
      <div class="bg-black opacity-50 absolute w-screen h-screen" @click="selecttab(0);hidebod();height();"></div>
      <div class="mx-auto relative opacity-100 sm:w-1/2 w-2/3" >
        <button type="button" name="button" class="text-white text-2xl bg-black p-1 z-10 rounded-full absolute -right-4 sm:right-0 top-2 sm:-top-3" @click="selecttab(0);hidebod();height();">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      selected: 0,
    }
  },
  methods: {
  selecttab(tab){
    this.selected = tab
  },
  hidebod () {
    this.isOpen = true
    document.querySelector('body').classList.toggle('overflow-hidden')
  },
  height () {
    this.isOpen = true
    document.querySelector('body').classList.toggle('max-h-screen')
  },
},
}
</script>
